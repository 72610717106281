<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <b-alert
            show
            variant="light"
            class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
        >
          <div class="alert-text mt-2 text-center">
            <h1 class="black--text"><b>Welcome to MyTCC</b></h1>
          </div>
        </b-alert>
      </v-col>
    </v-row>
    <v-card flat>
      <v-card-text>
        <v-form
            class="custom_form"
            ref="form"
            v-model="validationRules.valid"
            lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Profession</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  clearable
                  outlined
                  dense
                  :items="professions"
                  v-model="editedItem.profession"
                  item-text="title"
                  item-value="value"
                  label="Profession *"
                  :rules="validationRules.selectRules"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" v-if="editedItem.profession && editedItem.profession === 'Other'">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.other_profession" :counter="100"
                            maxLength="100"
                            :rules="validationRules.midRules"
                            label="Other Profession *"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Profile/Personal Details</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                  clearable
                  outlined
                  dense
                  :items="salutations"
                  v-model="editedItem.salutation"
                  item-text="title"
                  item-value="value"
                  label="Salutation *"
                  :rules="validationRules.selectRules"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.first_name" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="First Name *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.middle_name" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="Middle Name *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.last_name"
                            maxLength="50"
                            :counter="50"
                            :rules="validationRules.nameRules"
                            label="Last Name *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            type="date"
                            dense v-model="editedItem.dob"
                            :rules="validationRules.selectRules"
                            label="Date of Birth *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                  clearable
                  outlined
                  dense
                  :items="nationalities"
                  v-model="editedItem.nationality"
                  item-text="nationality"
                  item-value="nationality"
                  label="Nationality *"
                  :rules="validationRules.selectRules"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select clearable
                        outlined
                        dense v-model="editedItem.gender"
                        :items="genders"
                        item-text="title"
                        item-value="value"
                        :rules="validationRules.genderRules"
                        label="Gender *">
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.email" :counter="100"
                            maxLength="100"
                            readonly
                            disabled
                            outlined
                            :rules="validationRules.emailRules"
                            dense label="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.phone" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Phone *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <label style="color: black">Picture</label><br>
              <a style="color: blue" :href="editedItem.image_public_url" target="_blank">View</a>
            </v-col>
            <v-col cols="12" sm="3">
              <template>
                <v-file-input
                    accept="image/jpeg, image/png"
                    clearable
                    label="Update Picture"
                    outlined
                    :rules="fileRules"
                    dense
                    @change="uploadFile($event, 'user_new_image')"
                    show-size
                ></v-file-input>
              </template>
            </v-col>
            <v-col cols="12" sm="3">
              <label style="color: black">CV</label><br>
              <a style="color: blue" :href="editedItem.cv_public_url" target="_blank">View</a>
            </v-col>
            <v-col cols="12" sm="3">
              <template>
                <v-file-input
                    accept="application/pdf"
                    clearable
                    label="Update CV"
                    outlined
                    @change="uploadFile($event, 'user_new_cv')"
                    :rules="fileRules"
                    dense
                    show-size
                ></v-file-input>
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Personal Address</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.per_postal_address" :counter="100"
                            maxLength="100"
                            :rules="validationRules.midRules"
                            label="Postal Address *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.per_country" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="Country of Residence *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.per_city" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="City of Residence *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.per_tel" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Telephone *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.per_fax" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Fax *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.per_mobile" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Mobile *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.per_email" :counter="100"
                            maxLength="100"
                            clearable
                            outlined
                            :rules="validationRules.emailRules"
                            dense label="Email *"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Business Address</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.bus_postal_address" :counter="100"
                            maxLength="100"
                            :rules="validationRules.midRules"
                            label="Postal Address *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.bus_country" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="Country *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.bus_city" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="City *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.bus_tel" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Telephone *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.bus_fax" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Fax *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.bus_mobile" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Mobile *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="editedItem.bus_email" :counter="100"
                            maxLength="100"
                            clearable
                            outlined
                            :rules="validationRules.emailRules"
                            dense label="Email *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                  clearable
                  outlined
                  dense
                  :items="commViaQuestions"
                  v-model="editedItem.communication_via"
                  item-text="title"
                  item-value="value"
                  label="Preferred method of communication *"
                  :rules="validationRules.selectRules"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                  clearable
                  outlined
                  dense
                  :items="commTypeQuestions"
                  v-model="editedItem.communication_type"
                  item-text="title"
                  item-value="value"
                  label="Communication type *"
                  :rules="validationRules.selectRules"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.work_days" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="Working Days *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.work_hours" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="Working Hours *"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Academic Qualification(s)</v-card-title>
                <v-card-text class="white--text text-center">
                  Please provide photocopied evidence of your university degree duly certified in the
                  country of issuance with a legalized translation into English. Please attach evidence of
                  any other relevant academic qualifications, if any
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserDegrees
                        :docOld="editedItem.user_degrees ? editedItem.user_degrees : editedItem.user_degrees === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Languages</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserLanguages
                        :docOld="editedItem.user_languages ? editedItem.user_languages : editedItem.user_languages === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Employment History for legal professionals</v-card-title>
                <v-card-text class="white--text text-center">
                  Outline your employment history for at least the past 6 years and attach any relevant
                  employment certificates.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserEmpHistory
                        :docOld="editedItem.user_emp_history ? editedItem.user_emp_history : editedItem.user_emp_history === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Employment History for non-legal
                  professionals
                </v-card-title>
                <v-card-text class="white--text text-center">
                  Outline your employment history for at least the past 8 years and attach any relevant
                  employment certificates
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserEmpHistoryNonLegal
                        :docOld="editedItem.user_emp_history_non_legal ? editedItem.user_emp_history_non_legal : editedItem.user_emp_history_non_legal === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Fields of Expertise</v-card-title>
                <v-card-text class="text-center white--text">
                  Please select areas of Specialization
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                  clearable
                  outlined
                  dense
                  chips
                  deletable-chips
                  multiple
                  :items="foeFilter"
                  v-model="editedItem.expertise_list"
                  item-text="title"
                  item-value="value"
                  label="Fields of Expertise"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.other_fields_of_expertise" :counter="100"
                            maxLength="100"
                            label="Other (Please specify)"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Jurisdictional Experience</v-card-title>
                <v-card-text class="white--text text-center">
                  Outline the national jurisdiction(s) and legal system(s), to which your academic
                  qualification(s) and practice pertain
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserJurisdictionalExperience
                        :docOld="editedItem.user_jurisdictional_experience ? editedItem.user_jurisdictional_experience : editedItem.user_jurisdictional_experience === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Dispute Resolution/Arbitration Training</v-card-title>
                <v-card-text class="white--text text-center">
                  Please set out any training programs in dispute resolution and arbitration that you have
                  taken and provide evidence of completion of such training(s)
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserDisputeResolutionArbitrationTraining
                        :docOld="editedItem.user_dispute_resolution_arbitration_training ? editedItem.user_dispute_resolution_arbitration_training : editedItem.user_dispute_resolution_arbitration_training === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Select Your Experience</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  clearable
                  outlined
                  dense
                  :items="expQuestions"
                  v-model="editedItem.experience_type"
                  item-text="title"
                  item-value="value"
                  label="Select Your Experience"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row v-if="editedItem.experience_type && editedItem.experience_type === 'Arbitration'">
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Arbitration Experience</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserArbitrationExperience
                        :docOld="editedItem.user_arbitration_experience ? editedItem.user_arbitration_experience : editedItem.user_arbitration_experience === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            @keypress="isNumber($event)"
                            dense
                            v-model="editedItem.number_of_exp_in_arbitration" :counter="5"
                            maxLength="5"
                            label="Total Experience in Arbitration (in years)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select clearable
                        outlined
                        :items="questions"
                        item-text="title"
                        item-value="value"
                        dense
                        v-model="editedItem.exp_in_arbitration_is_other_inst"
                        label="Are you already empaneled as an Arbitrator with any other Institution?"></v-select>
            </v-col>
            <v-col cols="12" sm="6"
                   v-if="editedItem.exp_in_arbitration_is_other_inst && editedItem.exp_in_arbitration_is_other_inst === 'Yes'">
              <v-text-field clearable
                            outlined
                            dense
                            v-model="editedItem.exp_in_arbitration_other_inst_name" :counter="100"
                            maxLength="100"
                            label="Other Institution Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            @keypress="isNumber($event)"
                            dense
                            v-model="editedItem.number_of_arbitration_matters" :counter="5"
                            maxLength="5"
                            label="Number of Arbitration matters conducted by you as an Arbitrator in Ad-hoc arbitrations (as sole arbitrator or otherwise)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            @keypress="isNumber($event)"
                            dense
                            v-model="editedItem.number_of_arbitration_cases" :counter="5"
                            maxLength="5"
                            label="Number of cases conducted by you as a member of arbitral tribunal (sole arbitrator or otherwise) under the aegis of an Arbitral Institution"></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="editedItem.experience_type && editedItem.experience_type === 'Mediation'">
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Mediation Experience</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserMediationExperience
                        :docOld="editedItem.user_mediation_experience ? editedItem.user_mediation_experience : editedItem.user_mediation_experience === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            @keypress="isNumber($event)"
                            dense
                            v-model="editedItem.number_of_exp_in_mediation" :counter="5"
                            maxLength="5"
                            label="Total Experience in Mediation (in years)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select clearable
                        outlined
                        :items="questions"
                        item-text="title"
                        item-value="value"
                        dense
                        v-model="editedItem.exp_in_mediation_is_other_inst"
                        label="Are you already empaneled as an Mediator with any other Institution?"></v-select>
            </v-col>
            <v-col cols="12" sm="6"
                   v-if="editedItem.exp_in_mediation_is_other_inst && editedItem.exp_in_mediation_is_other_inst === 'Yes'">
              <v-text-field clearable
                            outlined
                            dense
                            v-model="editedItem.exp_in_mediation_other_inst_name" :counter="100"
                            maxLength="100"
                            label="Other Institution Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            @keypress="isNumber($event)"
                            dense
                            v-model="editedItem.number_of_mediation_cases" :counter="5"
                            maxLength="5"
                            label="Number of Mediations facilitated by you"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Awards</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserAwards
                        :docOld="editedItem.user_awards ? editedItem.user_awards : editedItem.user_awards === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Publications</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea clearable
                          outlined
                          dense v-model="editedItem.publications" :counter="500"
                          maxLength="500"
                          label="Publications"></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Professional Membership</v-card-title>
                <v-card-text class="white--text text-center">
                  Please list all dispute resolution institutes/ organizations of which you are a member.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserProfessionalMembership
                        :docOld="editedItem.user_professional_membership ? editedItem.user_professional_membership : editedItem.user_professional_membership === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Professional Certificates</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserProfessionalCertificates
                        :docOld="editedItem.user_professional_certificates ? editedItem.user_professional_certificates : editedItem.user_professional_certificates === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Accreditation</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <UserAccreditation
                        :docOld="editedItem.user_accreditation ? editedItem.user_accreditation : editedItem.user_accreditation === []"/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Additional Information</v-card-title>
                <v-card-text class="text-center white--text">
                  Provide any other information that supports your application and evidences your
                  professional standing in your field of work and your ability to deliver independent and
                  unbiased rulings. Attach extra documents as necessary.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea clearable
                          outlined
                          dense v-model="editedItem.additional_information" :counter="500"
                          maxLength="500"
                          label="Additional Information"></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Professional Biography</v-card-title>
                <v-card-text class="text-center white--text">
                  As it should appear on the Panel Directory. You may complete or update this at a later date via your
                  MyTCC account.
                </v-card-text>
                <v-card-text class="text-center white--text">
                  Please provide information on your background and areas of expertise. Please do not exceed the 3000
                  character limit (including spaces). Any text beyond the 3000 character limit will not be published.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea clearable
                          outlined
                          dense v-model="editedItem.bio" :counter="3000"
                          maxLength="3000"
                          :rules="validationRules.bioRules"
                          label="Professional Biography *"></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Social Media Links</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.facebook_url" :counter="200"
                            maxLength="200"
                            :append-icon="'mdi-facebook'"
                            :rules="validationRules.urlRules"
                            label="Facebook URL *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.linkedin_url" :counter="200"
                            maxLength="200"
                            :append-icon="'mdi-linkedin'"
                            :rules="validationRules.urlRules"
                            label="Linkedin URL *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.twitter_url" :counter="200"
                            maxLength="200"
                            :append-icon="'mdi-twitter'"
                            :rules="validationRules.urlRules"
                            label="Twitter URL *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.instagram_url" :counter="200"
                            maxLength="200"
                            :append-icon="'mdi-instagram'"
                            :rules="validationRules.urlRules"
                            label="Instagram URL *"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!validationRules.valid" class="btn btn-primary" dark text @click="saveItem(editedItem)">
          Update Profile
        </v-btn>
      </v-card-actions>
      <v-col cols="12">
        <div v-if="errors!=undefined && errors.length >= 0"
             role="alert"
             v-bind:class="{ show: errors.length }"
             class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import ModuleMedia from "@/view/pages/realstate/media/ModuleMedia";
import UserDegrees from "@/view/pages/auth/inc/UserDegrees";
import UserLanguages from "@/view/pages/auth/inc/UserLanguages";
import UserEmpHistory from "@/view/pages/auth/inc/UserEmpHistory";
import UserEmpHistoryNonLegal from "@/view/pages/auth/inc/UserEmpHistoryNonLegal";
import UserJurisdictionalExperience from "@/view/pages/auth/inc/UserJurisdictionalExperience";
import UserDisputeResolutionArbitrationTraining from "@/view/pages/auth/inc/UserDisputeResolutionArbitrationTraining";
import UserArbitrationExperience from "@/view/pages/auth/inc/UserArbitrationExperience";
import UserMediationExperience from "@/view/pages/auth/inc/UserMediationExperience";
import UserAwards from "@/view/pages/auth/inc/UserAwards";
import UserProfessionalMembership from "@/view/pages/auth/inc/UserProfessionalMembership";
import UserProfessionalCertificates from "@/view/pages/auth/inc/UserProfessionalCertificates";
import UserAccreditation from "@/view/pages/auth/inc/UserAccreditation";
import Loader from "@/view/content/Loader";
import Swal from "sweetalert2";
import {CLEAR_USER_ERRORS, UPDATE_USER_PROFILE} from "@/core/services/store/users.module";
import {UPLOAD_USER_FILE} from "@/core/services/store/auth.module";

export default {
  name: 'user-profile',
  components: {
    Loader,
    UserAccreditation,
    UserProfessionalCertificates,
    UserProfessionalMembership,
    UserAwards,
    UserMediationExperience,
    UserArbitrationExperience,
    UserDisputeResolutionArbitrationTraining,
    UserJurisdictionalExperience,
    UserEmpHistoryNonLegal, UserEmpHistory, UserLanguages, UserDegrees, ModuleMedia
  },
  data() {
    return {
      editedItem: {},
      show: false,
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 50) || "Must be less than or equal to 50 characters"
        ],
        bioRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 10) || "Must be greater than or equal to 10 characters",
          v => (v && v.length <= 3000) || "Must be less than or equal to 3000 characters"
        ],
        midRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 100) || "Must be less than or equal to 100 characters"
        ],
        degRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 100) || "Must be less than or equal to 100 characters"
        ],
        uniRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 200) || "Must be less than or equal to 200 characters"
        ],
        urlRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 10) || "Must be greater than or equal to 10 characters",
          v => (v && v.length <= 200) || "Must be less than or equal to 200 characters"
        ],
        ageRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than or equal to 1 characters",
          v => (v && v.length <= 3) || "Must be less than or equal to 3 characters"
        ],
        phoneRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 20) || "Must be less than or equal to 20 characters"
        ],
        genderRules: [
          v => !!v || "This field is required"
        ],
        sourceRules: [
          v => !!v || "This field is required"
        ],
        emailRules: [
          v => !!v || "This field  is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        selectRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'This field is required';
            else return true;
          }
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length <= 20) || "Password must be less than or equal to 20 characters",
          v => (v && v.length >= 6) || "Password must be at least 6 characters"
        ],
      },
      genders: [
        {value: 'Male', title: 'Male'},
        {value: 'Female', title: 'Female'},
        {value: 'Other', title: 'Other'}
      ],
      professions: [
        {value: 'Lawyer', title: 'Lawyer'},
        {value: 'Advocate', title: 'Advocate'},
        {value: 'Barrister', title: 'Barrister'},
        {value: 'Doctor', title: 'Doctor'},
        {value: 'Engineer', title: 'Engineer'},
        {value: 'Hon’ble Justice', title: 'Hon’ble Justice'},
        {value: 'Retd. Justice', title: 'Retd. Justice'},
        {value: 'Architect', title: 'Architect'},
        {value: 'Chartered Accountant', title: 'Chartered Accountant'},
        {value: 'Company Secretary', title: 'Company Secretary'},
        {value: 'Costs and Works Accountant', title: 'Costs and Works Accountant'},
        {value: 'Service', title: 'Service'},
        {value: 'Other', title: 'Other (please specify)'},
      ],
      salutations: [
        {value: 'Mr.', title: 'Mr.'},
        {value: 'Mrs.', title: 'Mrs.'},
        {value: 'Ms.', title: 'Ms.'},
        {value: 'Dr.', title: 'Dr.'},
        {value: 'Barrister', title: 'Barrister'},
        {value: 'Adv.', title: 'Adv.'},
        {value: 'Engr.', title: 'Engr.'},
        {value: 'Hon’ble Justice', title: 'Hon’ble Justice'},
        {value: 'Retd. Justice', title: 'Retd. Justice'}
      ],
    }
  },
  watch: {},
  mounted() {
    this.editedItem = this.$store.state.auth.user
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        this.$set(item, "userDegrees", this.$store.state.auth.userDegrees);
        this.$set(item, "userLanguages", this.$store.state.auth.userLanguages);
        this.$set(item, "userEmpHistory", this.$store.state.auth.userEmpHistory);
        this.$set(item, "userEmpHistoryNonLegal", this.$store.state.auth.userEmpHistoryNonLegal);
        this.$set(item, "userJurisdictionalExperience", this.$store.state.auth.userJurisdictionalExperience);
        this.$set(item, "userDisputeResolutionArbitrationTraining", this.$store.state.auth.userDisputeResolutionArbitrationTraining);
        this.$set(item, "userArbitrationExperience", this.$store.state.auth.userArbitrationExperience);
        this.$set(item, "userMediationExperience", this.$store.state.auth.userMediationExperience);
        this.$set(item, "userAwards", this.$store.state.auth.userAwards);
        this.$set(item, "userProfessionalMembership", this.$store.state.auth.userProfessionalMembership);
        this.$set(item, "userProfessionalCertificates", this.$store.state.auth.userProfessionalCertificates);
        this.$set(item, "userAccreditation", this.$store.state.auth.userAccreditation);
        this.$set(item, "deletedDocuments", this.$store.state.users.deletedDocuments);
        this.$store.dispatch(CLEAR_USER_ERRORS);
        // save the record
        this.$store.dispatch(UPDATE_USER_PROFILE, {'slug': item.id, data: item}).then((data) => {
          Swal.fire('', data._metadata.message, 'success').then(() => {
            window.location.reload();
          })
        })
      }
    },
    uploadFile(file, key) {
      if (file) {
        this.$store.dispatch(CLEAR_USER_ERRORS);
        let formData = new FormData()
        formData.append("file", file, file.name)
        this.$store.dispatch(UPLOAD_USER_FILE, {data: formData}).then((data) => {
          this.editedItem[key] = data.records
        })
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    })
  }
};
</script>